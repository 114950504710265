import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as reactCookie from 'react-cookie';
import get from 'lodash/get';
import {
  setRevisitUser,
} from 'shared/components/App/app-actions';
import { closeModal } from 'shared/components/Modal/actions';
import { openWelcomeMat } from './actions';

class WelcomeMat extends Component {
  componentDidMount() {
    if (this.showWelcomeMat()) {
      const modal = {
        closeDisabled: false,
      };
      const wcMatProps = {
        closeModal: this.closeWCModal,
        startShopping: this.startShopping,
      };
      this.props.openWelcomeMat(modal, wcMatProps);
    }
    this.userVisited();
  }

  closeWCModal = () => {
    return this.props.closeModal('WelcomeMatPopup');
  }

  startShopping = () => {
    this.closeWCModal();
  }

  isFirstTimeUser() {
    return !this.props.revisitUser;
  }

  showWelcomeMat() {
    return this.isFirstTimeUser() && this.props.isUnitedStatesUser === 'False';
  }

  userVisited() {
    reactCookie.save('revisitUser', true, { path: '/' });
    this.props.setRevisitUser(true, this.props.toggles);
  }

  render() {
    if (this.showWelcomeMat()) {
      return (
        <div id="welcome-mat" />
      );
    }
    return false;
  }
}

const mapStateToProps = (state) => ({
  revisitUser: state.user.revisitUser,
  toggles: state.toggles,
  isUnitedStatesUser: get(state, 'session.isUnitedStatesUser', 'True'),
});

const mapDispatchToProps = ({
  setRevisitUser,
  openWelcomeMat,
  closeModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeMat);

import React, { createRef, PureComponent } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import window from 'window-or-global';
import { Provider as PanelProvider } from './PanelContext';
import { APP_SET_USER } from '../App/app-action-types';

const YourNeimans = Loadable({
  loader: () => import(/* webpackChunkName: 'your-neimans-panel' */ './YourNeimans'),
  loading: () => false,
});

export class DumbPanelContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.panelContainer = createRef();

    const {
      showPanel,
      YOUR_NEIMANS_SA,
      YOUR_NEIMANS_DEFAULT_SA,
      YOUR_NEIMANS_SA_INTERACTION,
      YOUR_NEIMANS_SA_SEND_BAG,
      YOUR_NEIMANS_SA_EMAIL,
      SALES_ASSOCIATES_V2,
      LOCATION,
      LOGIN_PANEL,
      INCIRCLE_PANEL_PAGE,
      GIFTCARDS_PANEL,
      PANEL_OFFERS,
      PROMO_NOTIFICATIONS,
      PROMOGC_PANEL,
      GUEST_IDENTITY_DT,
      FINGERPRINT_PRO,
      IMPROVED_PANEL_CTA,
      MY_STYLE_PREFERENCES,
      GIFT_CARD_NOTIFICATIONS,
      INCIRCLE_NOTIFICATIONS,
      DT_LOGIN_FLOW,
      UCA_PROFILE_COOKIE,
      EMPLOYEE_STATUS_V2,
      DISCOUNT_ELIGIBILITY_V1,
      LOCK_AUTHSTATUS_API,
      PZP_IDENTITY,
      NEW_STORES_UI,
      DISABLE_ATG_LOGIN,
      BRAND_NAME,
      UCA_MFA,
      PANEL_SCRIPT,
      ADOBE_PZP_IDENTITY,
      SHOW_INCIRCLE_YEAR,
      VALIDATE_LOGIN,
      P13N_API,
      USE_CONFIDENTIAL_CLIENT,
      USE_SRP_AUTH,
      USE_AUTH_CONFIG,
      FP_OPENSOURCE,
      NMO_LOYALTY_NEW,
      LOY_LANDING,
    } = props;

    if (showPanel) {
      window.YourNeimansConfig = {
        YOUR_NEIMANS_SA,
        YOUR_NEIMANS_DEFAULT_SA,
        YOUR_NEIMANS_SA_INTERACTION,
        YOUR_NEIMANS_SA_SEND_BAG,
        YOUR_NEIMANS_SA_EMAIL,
        SALES_ASSOCIATES_V2,
        LOCATION,
        LOGIN_PANEL,
        INCIRCLE_PANEL_PAGE,
        GIFTCARDS_PANEL,
        PANEL_OFFERS,
        PROMO_NOTIFICATIONS,
        PROMOGC_PANEL,
        GUEST_IDENTITY_DT,
        FINGERPRINT_PRO,
        IMPROVED_PANEL_CTA,
        MY_STYLE_PREFERENCES,
        GIFT_CARD_NOTIFICATIONS,
        INCIRCLE_NOTIFICATIONS,
        DT_LOGIN_FLOW,
        UCA_PROFILE_COOKIE,
        EMPLOYEE_STATUS_V2,
        DISCOUNT_ELIGIBILITY_V1,
        LOCK_AUTHSTATUS_API,
        PZP_IDENTITY,
        NEW_STORES_UI,
        DISABLE_ATG_LOGIN,
        BRAND_NAME,
        UCA_MFA,
        PANEL_SCRIPT,
        ADOBE_PZP_IDENTITY,
        SHOW_INCIRCLE_YEAR,
        VALIDATE_LOGIN,
        P13N_API,
        USE_CONFIDENTIAL_CLIENT,
        USE_SRP_AUTH,
        USE_AUTH_CONFIG,
        FP_OPENSOURCE,
        NMO_LOYALTY_NEW,
        LOY_LANDING,
      };
    }
  }

  updateSiteHeader = (name, email = '') => {
    this.props.dispatch({
      type: APP_SET_USER,
      user: {
        name,
        securityStatus: 'Authenticated',
        email,
      },
    });
  }

  render() {
    const { showPanel } = this.props;
    return (
      showPanel && (
        <PanelProvider>
          <YourNeimans updateSiteHeader={this.updateSiteHeader} />
        </PanelProvider>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  const YOUR_NEIMANS_PANEL = get(state, 'toggles.YOUR_NEIMANS_PANEL', false);
  const YOUR_NEIMANS_SA = get(state, 'toggles.YOUR_NEIMANS_SA', false);
  const YOUR_NEIMANS_DEFAULT_SA = get(state, 'toggles.YOUR_NEIMANS_DEFAULT_SA', false,);
  const YOUR_NEIMANS_SA_INTERACTION = get(state, 'toggles.YOUR_NEIMANS_SA_INTERACTION', false,);
  const YOUR_NEIMANS_SA_SEND_BAG = get(state, 'toggles.YOUR_NEIMANS_SA_SEND_BAG', false);
  const YOUR_NEIMANS_SA_EMAIL = get(state, 'toggles.YOUR_NEIMANS_SA_EMAIL', false);
  const SALES_ASSOCIATES_V2 = get(state, 'toggles.SALES_ASSOCIATES_V2', false);
  const LOGIN_PANEL = get(state, 'toggles.LOGIN_PANEL', false);
  const LOCATION = get(state, 'api.requestContext.akamaiEdgescape.zip', '');
  const INCIRCLE_PANEL_PAGE = get(state, 'toggles.INCIRCLE_PANEL_PAGE', false);
  const GIFTCARDS_PANEL = get(state, 'toggles.GIFTCARDS_PANEL', false);
  const PANEL_OFFERS = get(state, 'toggles.PANEL_OFFERS', false);
  const PROMO_NOTIFICATIONS = get(state, 'toggles.PROMO_NOTIFICATIONS', false);
  const PROMOGC_PANEL = get(state, 'toggles.PROMOGC_PANEL', false);
  const GUEST_IDENTITY_DT = get(state, 'toggles.GUEST_IDENTITY_DT', false);
  const IMPROVED_PANEL_CTA = get(state, 'toggles.IMPROVED_PANEL_CTA', false);
  const MY_STYLE_PREFERENCES = get(state, 'toggles.MY_STYLE_PREFERENCES', false);
  const GIFT_CARD_NOTIFICATIONS = get(state, 'toggles.GIFT_CARD_NOTIFICATIONS', false);
  const INCIRCLE_NOTIFICATIONS = get(state, 'toggles.INCIRCLE_NOTIFICATIONS', false);
  const DT_LOGIN_FLOW = get(state, 'toggles.DT_LOGIN_FLOW', false);
  const UCA_PROFILE_COOKIE = get(state, 'toggles.UCA_PROFILE_COOKIE', false);
  const EMPLOYEE_STATUS_V2 = get(state, 'toggles.EMPLOYEE_STATUS_V2', false);
  const DISCOUNT_ELIGIBILITY_V1 = get(state, 'toggles.DISCOUNT_ELIGIBILITY_V1', false);
  const LOCK_AUTHSTATUS_API = get(state, 'toggles.LOCK_AUTHSTATUS_API', false);
  const PZP_IDENTITY = get(state, 'toggles.PZP_IDENTITY', false);
  const DISABLE_ATG_LOGIN = get(state, 'toggles.DISABLE_ATG_LOGIN', false);
  const NEW_STORES_UI = get(state, 'toggles.NEW_STORES_UI', false);
  const BRAND_NAME = get(state, 'brand_name.env', 'NM');
  const UCA_MFA = get(state, 'toggles.UCA_MFA', false);
  const PANEL_SCRIPT = get(state, 'toggles.PANEL_SCRIPT', false);
  const ADOBE_PZP_IDENTITY = get(state, 'toggles.ADOBE_PZP_IDENTITY', false);
  const SHOW_INCIRCLE_YEAR = get(state, 'toggles.SHOW_INCIRCLE_YEAR', false);
  const VALIDATE_LOGIN = get(state, 'toggles.VALIDATE_LOGIN', false);
  const P13N_API = get(state, 'toggles.P13N_API', false);
  const USE_CONFIDENTIAL_CLIENT = get(state, 'toggles.USE_CONFIDENTIAL_CLIENT', false);
  const USE_SRP_AUTH = get(state, 'toggles.USE_SRP_AUTH', false);
  const USE_AUTH_CONFIG = get(state, 'toggles.USE_AUTH_CONFIG', false);
  const FP_OPENSOURCE = get(state, 'toggles.FP_OPENSOURCE', false);
  const NMO_LOYALTY_NEW = get(state, 'toggles.NMO_LOYALTY_NEW', false);
  const LOY_LANDING = get(state, 'toggles.LOY_LANDING', false);
  const USE_MFA_API = get(state, 'toggles.USE_MFA_API', false);

  return {
    showPanel: isDomestic && YOUR_NEIMANS_PANEL,
    YOUR_NEIMANS_SA,
    YOUR_NEIMANS_DEFAULT_SA,
    YOUR_NEIMANS_SA_INTERACTION,
    YOUR_NEIMANS_SA_SEND_BAG,
    YOUR_NEIMANS_SA_EMAIL,
    SALES_ASSOCIATES_V2,
    LOCATION,
    LOGIN_PANEL,
    INCIRCLE_PANEL_PAGE,
    GIFTCARDS_PANEL,
    PANEL_OFFERS,
    PROMO_NOTIFICATIONS,
    PROMOGC_PANEL,
    GUEST_IDENTITY_DT,
    IMPROVED_PANEL_CTA,
    MY_STYLE_PREFERENCES,
    GIFT_CARD_NOTIFICATIONS,
    INCIRCLE_NOTIFICATIONS,
    DT_LOGIN_FLOW,
    UCA_PROFILE_COOKIE,
    EMPLOYEE_STATUS_V2,
    DISCOUNT_ELIGIBILITY_V1,
    LOCK_AUTHSTATUS_API,
    PZP_IDENTITY,
    NEW_STORES_UI,
    DISABLE_ATG_LOGIN,
    BRAND_NAME,
    UCA_MFA,
    PANEL_SCRIPT,
    ADOBE_PZP_IDENTITY,
    SHOW_INCIRCLE_YEAR,
    VALIDATE_LOGIN,
    P13N_API,
    USE_CONFIDENTIAL_CLIENT,
    USE_SRP_AUTH,
    USE_AUTH_CONFIG,
    FP_OPENSOURCE,
    NMO_LOYALTY_NEW,
    LOY_LANDING,
    USE_MFA_API,

  };
};

export default connect(mapStateToProps)(DumbPanelContainer);
